<template>
  <v-card class="elevation-x">
    <v-card-text>
      <v-timeline v-if="$auth.granted('role_professionals')" align-top dense>
        <v-timeline-item v-for="(item, i) in items" :key="i" :color="item.color" small>
          <v-row class="pt-1">
            <v-col cols="3" class="font-alt">
              {{ item.type }}
            </v-col>
            <v-col class="font-alt">
              <div v-html="item.text" />
              <div class="caption">
                {{ item.date }}
              </div>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>

      <v-timeline v-else align-top dense>
        <v-timeline-item v-for="(item, i) in items2" :key="i" :color="item.color" small>
          <v-row class="pt-1">
            <v-col cols="3" class="font-alt">
              {{ item.type }}
            </v-col>
            <v-col class="font-alt">
              <div v-html="item.text" />
              <div class="caption">
                {{ item.date }}
              </div>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'TeamHistoric',
    data: () => ({
      items: [
        {
          type: 'Actions',
          text: 'Le médecin ' +
            '<strong class="primary--text">Mickael Barre</strong> ' +
            'a partagé une publication de ' +
            '<strong class="primary--text">' + 'Guillaume Paut'+ '</strong>',
          date: '31 janvier 2021',
          color: 'primary'
        },
        {
          type: 'Document',
          text: 'Le médecin ' +
            '<strong class="yellow--text text--darken-3">Guillaume Paut</strong> ' +
            'a partagé le document ' +
            '<strong class="yellow--text text--darken-3">' + 'Opération du pied'+ '</strong>',
          date: '30 janvier 2021',
          color: 'yellow darken-3'
        },
        {
          type: 'Document',
          text: 'Le médecin ' +
            '<strong class="yellow--text text--darken-3">Guillaume Paut</strong> ' +
            'a partagé le document ' +
            '<strong class="yellow--text text--darken-3">' + 'Radio du pied'+ '</strong>',
          date: '28 janvier 2021',
          color: 'yellow darken-3'
        },
        {
          type: 'Actions',
          text: 'Le médecin ' +
            '<strong class="primary--text">Mickael Barre</strong> ' +
            'a publié un nouveau message ',
          date: '1 janvier 2021',
          color: 'primary'
        },
        {
          type: 'Invitation',
          text: 'Le médecin ' +
            '<strong class="secondary--text">Mickael Barre</strong> ' +
            'a invité ' +
            '<strong class="secondary--text">Guillaume Paut</strong> ' +
            'à rejoindre le groupe',
          date: '2 décembre 2020',
          color: 'secondary'
        },
        {
          type: 'Actions',
          text: 'Le médecin ' +
            '<strong class="primary--text">Mickael Barre</strong> ' +
            'a modifié les informations du groupe ',
          date: '2 novembre 2020',
          color: 'primary'
        }
      ],
      items2: [
        {
          type: 'Actions',
          text: '<strong class="primary--text">Mickael Barre</strong> ' +
            'a partagé une publication de ' +
            '<strong class="primary--text">' + 'Guillaume Paut'+ '</strong>',
          date: '31 janvier 2021',
          color: 'primary'
        },
        {
          type: 'Document',
          text: '<strong class="yellow--text text--darken-3">Guillaume Paut</strong> ' +
            'a partagé le document ' +
            '<strong class="yellow--text text--darken-3">' + 'Opération du pied'+ '</strong>',
          date: '30 janvier 2021',
          color: 'yellow darken-3'
        },
        {
          type: 'Document',
          text: '<strong class="yellow--text text--darken-3">Guillaume Paut</strong> ' +
            'a partagé le document ' +
            '<strong class="yellow--text text--darken-3">' + 'Radio du pied'+ '</strong>',
          date: '28 janvier 2021',
          color: 'yellow darken-3'
        },
        {
          type: 'Actions',
          text: '<strong class="primary--text">Mickael Barre</strong> ' +
            'a modifié les informations du groupe ',
          date: '1 janvier 2021',
          color: 'primary'
        },
        {
          type: 'Invitation',
          text: '<strong class="secondary--text">Mickael Barre</strong> ' +
            'a invité le patient ' +
            '<strong class="secondary--text">Guillaume Paut</strong> ' +
            'à rejoindre le groupe',
          date: '2 décembre 2020',
          color: 'secondary'
        },
        {
          type: 'Actions',
          text: '<strong class="primary--text">Mickael Barre</strong> ' +
            'a créé le groupe ' +
            '<strong class="primary--text">Groupe autour du diabete</strong> ',
          date: '2 novembre 2020',
          color: 'primary'
        }
      ]
    })
  }
</script>
